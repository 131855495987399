import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Section } from "./../components/Core";
import Seo from "./../components/Seo/Seo";
import CheckAvailability from "../components/Rebrand/CheckAvailability/CheckAvailability";

const CheckAvailabilityPage = (props) => {

    return (
        <>
            <Seo page={`check-availability ${props.center && 'center'}`} />
            <Section className="check-availability-page green-bg text-left">
                <Container>
                    <Row>
                        <Col sm={8}>
                            <h1 className="mb-5">Have we got you covered?</h1><br />
                            <p className="large-font">Enter your postcode below to find out</p>
                            <CheckAvailability minimized={true} />
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
};
export default CheckAvailabilityPage;
